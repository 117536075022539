import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { useCalendarStyles } from './styles'
import { Headline3 } from '@/shared/components/Typography';
import { useIntl } from 'react-intl';
import { sectionMessages } from '../messages';

function CalendarPlaceholder() {
    const classes = useCalendarStyles({ isPlaceholder: true })
    const intl = useIntl()
    return (
        <Grid spacing={0} container justifyContent='center'>
            <Grid item xs={12}>
                <Box pb={4}>
                    <Headline3>{intl.formatMessage(sectionMessages['calendar.no_events'])}</Headline3>
                </Box>
            </Grid>
            <Hidden smDown>
                <Grid container justifyContent='flex-end' item md={6} className={classes.leftSide}>
                    <div className={`${classes.placeholderWrapper} left`}/>
                </Grid>
            </Hidden>
            <Grid container justifyContent='flex-start' item sm={12} xs={12} md={6} className={classes.verticalLine}>
                <div className={`${classes.placeholderWrapper} right`}/>
            </Grid>
        </Grid>
    )
}

export default CalendarPlaceholder