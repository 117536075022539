import React from 'react';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

const tagStyles = makeStyles(() => ({
  tag: {
    padding: '4px 8px',
    width: 'fit-content',
    maxWidth: 300,
    borderRadius: 16,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tagName: {
    fontSize: 11,
    fontWeight: 700,
  },
}));

interface IProps {
  name: string;
  color: string;
  assignationDate?: string;
}

const TagManagementTag: React.FunctionComponent<IProps> = (props) => {
  const { name, color, assignationDate } = props;
  const classes = tagStyles();
  const textColor = isLightColor() ? '#0b1f40' : '#ffffff';

  function isLightColor(): boolean {
    let r, g, b;

    if (color.charAt(0) === '#') {
      r = parseInt(color.substring(1, 3), 16);
      g = parseInt(color.substring(3, 5), 16);
      b = parseInt(color.substring(5, 7), 16);
    } else if (color.indexOf('rgb') !== -1) {
      const start = color.indexOf('(') + 1,
        end = color.indexOf(')'),
        values = color.substring(start, end).split(',');
      r = parseInt(values[0]);
      g = parseInt(values[1]);
      b = parseInt(values[2]);
    }

    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 125;
  }

  return (
    <div className={classes.tag} style={{ backgroundColor: color, color: textColor }}>
      <span className={classes.tagName} style={{ color: textColor }}>
        {name} {assignationDate ? `(${moment(assignationDate).format('DD/MM/YY')})` : null}
      </span>
    </div>
  );
};

export default TagManagementTag;
