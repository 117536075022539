import Chip from '@/shared/components/Chip/Chip';
import ValidatedSelectInput from '@/shared/components/Form/Material/ValidatedSelectInput';
import { Separator } from '@/shared/components/Separator/Separator';
import { Headline2 } from '@/shared/components/Typography';
import Box from '@material-ui/core/Box/Box';
import { sectionMessages } from 'components/Sections/messages';
import { buildUrlFromParams, ISectionComponentProps } from 'components/Sections/utils';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { getEvent, getWebsiteEvents } from 'services/events';
import CalendarList from '../CalendarList';
import { CALENDAR_PAGE_PAGINATION_LIMIT } from '../const';
import InfiniteScroll from '@/shared/components/InfiniteScroll';
import { useCalendarStyles, useInfiniteListStyles } from '../styles';
import { IEvent } from '@/shared/models/Event';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import TextButton from '@/shared/components/Button/TextButton';
import moment from 'moment';
import { DATE_ISO_FORMAT } from '@/shared/utils/formDateUtils';
import { useTranslations } from 'contexts/translations';
import { useClub } from 'contexts/club';
import { getGroupOptions } from '@/shared/utils/group';
import { unique } from '@/shared/utils/unique';
import { AdManager } from '@/shared/components/AdManager/AdManager';
import { sortCalendarEventsByStartDate } from '@/shared/utils/sorter';

export default function CalendarDefaultLayout(props: ISectionComponentProps) {
    const intl = useIntl()
    const router = useRouter()
    const { slug } = router.query
    const { club, prefix, pathPrefix, modes } = useClub()
    const { translations } = useTranslations()
    const { groups, group, events, countries, categories, activeTypes, eventsList, placeholders, section, tags } = props.data
    const messages = useMemo(() => ({...translations.messages, ...placeholders[translations.locale]}), [translations, placeholders])
    const eventsLoaded = useRef(false)
    const [currentGroup, setCurrentGroup] = useState(group?.id || 0)
    const eventTypeOptions = useMemo(() => [
        { label: intl.formatMessage(sectionMessages['section.calendar.events']), value: 'event'},
        { label: intl.formatMessage(sectionMessages['section.calendar.trainings']), value: 'training'},
        { label: intl.formatMessage(sectionMessages['section.calendar.games']), value: 'game'}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [])
    const [currentDate, setCurrentDate] = useState(props.data.currentDate)
    const [currentEvents, setCurrentEvents] = useState(events)
    const [currentEventIds, setCurrentEventIds] = useState(eventsList)
    const [isFetching, setIsFetching] = useState(false)
    const [currentActiveTypes, setCurrentActiveTypes] = useState<string[]>(activeTypes)
    const classes = useInfiniteListStyles()
    const calendarClasses = useCalendarStyles({})
    const [hasMore, setHasMore] = useState(eventsList.length > 0)
    const [activePage, setActivePage] = useState(0)
    const [limitedCalendar, setLimitedCalendar] = useState(false)
    const { simpleDateFormatter } = useTranslations()

    useEffect(() => {
        if(eventsLoaded.current) {
            setHasMore(true)
            setActivePage(0)
            // const tagQuery = props.section?.content?.tags?.length ?
            //     props.section.content.tags.map((t) => t.tag.id).join(',') : ''
            fetchMore({ group: currentGroup, types: currentActiveTypes, offset: 0 })
        } else {
            setActivePage(page => page + 1)
        }
        const basePrefix = props.isPreview ? `${pathPrefix}/preview` : pathPrefix
        const basePath = `/${[basePrefix, slug[0]].join('/')}`
        const url = buildUrlFromParams(basePath, {
            groups: currentGroup,
        }, ['groups'], {
            ...currentActiveTypes.length && {
                types: currentActiveTypes.join(',')
            },
            date: currentDate
        })
        router.replace(url, undefined, { shallow: true })
        eventsLoaded.current = true
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentActiveTypes, currentGroup, currentDate])

    useEffect(() => {
        if (props.section?.content?.type?.length > 0) {
            setCurrentActiveTypes(props.section.content.type)
        }
        if (props.section?.content?.team) {
            setCurrentGroup(props.section?.content?.team?.id)
        }
        if (props.section?.content?.team || props.section?.content?.type?.length < 3) {
            setLimitedCalendar(true)
        }
    }, [])

    function renderAdManager(blockId): JSX.Element {
        const adClub = club?.id ?? 0

        return (
            <div key={`${blockId}-ad`} className={calendarClasses.adRoot}>
                {adClub && (
                    <AdManager
                        adType='calendar_overview'
                        blockId={blockId}
                        sizes={[[300, 50], [234, 60]]}
                        color={modes.find(m => m.type === section?.mode.type)?.background}
                    />
                )}
            </div>
        )
    }

    const groupOptions = useMemo(() => getGroupOptions(groups, messages,  unique(groups.map(e => e?.sport?.id).filter(Boolean)).length > 1), [groups, messages])
    return (
        <Box>
            <Box pb={4}>
                <Headline2>{intl.formatMessage(sectionMessages['section.calendar.label'])}</Headline2>
            </Box>
            {!limitedCalendar && (
                <>
                    <Box pb={4} className={calendarClasses.groupSelector}>
                        <ValidatedSelectInput
                            id='group'
                            value={currentGroup}
                            name='group'
                            label={intl.formatMessage(sectionMessages['section.calendar.calendar_for'])}
                            options={[{ label: intl.formatMessage(sectionMessages['section.calendar.all']), value: 0 }].concat(groupOptions) }
                            onChange={(event) => {
                                setCurrentGroup(Number(event.target.value))
                            }}
                        />
                        {renderAdManager(club.id)}
                    </Box>
                    <Box pb={4} display='flex'>
                        {eventTypeOptions.map(option => 
                            <Box key={option.value} mr={2} className={isFetching && calendarClasses.noPointerEvents}>
                                <Chip active={currentActiveTypes.includes(option.value)} label={option.label} onClick={() => onToggleFilter(option.value)}/>
                            </Box>
                        )}
                    </Box>
                </>
            )}
            <Box pb={4}>
                <Separator/>
            </Box>
            <Box display='flex' justifyContent='center'>
                <TextButton
                    className={calendarClasses.navigationButton}
                    href={`?${new URLSearchParams({ date: getNextDate('prev') })}`}
                    onClick={(e) => {
                    e.preventDefault()
                    onNavigate('prev')
                }} startIcon={<ArrowBackIosIcon />}/>
                <Headline2 className={calendarClasses.noWrap}>
                    {simpleDateFormatter(moment(currentDate, DATE_ISO_FORMAT).toDate(), 'MMMM YYYY')}
                </Headline2>
                <TextButton
                    className={calendarClasses.navigationButton}
                    href={`?${new URLSearchParams({ date: getNextDate('next') })}`}
                    onClick={(e) => {
                        e.preventDefault()
                        onNavigate('next')
                }} startIcon={<ArrowForwardIosIcon />}/>
            </Box>
            <InfiniteScroll
                dataLength={currentEvents.length}
                next={fetchNext}
                hasMore={hasMore}
                loader={null}
                className={classes.infiniteList}
            >
                <CalendarList placeholders={placeholders} isFetching={isFetching} countries={countries} categories={categories} events={currentEvents} tags={tags} />
            </InfiniteScroll>
        </Box>
    )

    function onNavigate(direction: 'prev' | 'next') {
        const date = getNextDate(direction)
        setCurrentDate(date)
    }

    function getNextDate(direction: 'prev' | 'next') {
        return direction === 'prev' ? moment(currentDate, DATE_ISO_FORMAT).subtract(1, 'month').format(DATE_ISO_FORMAT) : moment(currentDate, DATE_ISO_FORMAT).add(1, 'month').format(DATE_ISO_FORMAT)
    }

    function onToggleFilter(key: string) {
        setCurrentActiveTypes(prev => prev.includes(key) ? prev.filter(e => e!== key): [...prev, key])
    }

    function fetchNext() {
        if (activePage === 0) return
        // const tagQuery = props.section?.content?.tags?.length ?
        //     props.section.content.tags.map((t) => t.tag.id).join(',') : ''
        return fetchMore({ group: currentGroup, types: currentActiveTypes, offset: activePage * CALENDAR_PAGE_PAGINATION_LIMIT })
    }

    async function fetchMore({ group, types, offset }: { group: number, types: string[], offset: number, tagQuery?: string }) {
        setIsFetching(true)
        let nextEventIds: IEvent[] = []
        if(offset === 0) {
            const startDate = moment(currentDate, DATE_ISO_FORMAT).startOf('month').format(DATE_ISO_FORMAT)
            const endDate = moment(currentDate, DATE_ISO_FORMAT).endOf('month').format(DATE_ISO_FORMAT)
            setCurrentEvents([])
            const params = {
                ...group && { _group: group },
                // ...tagQuery && { _tags: tagQuery },
                _date: startDate,
                _max_start_date: endDate,
                _dir: 'future'
            }
            if(types.length) {
                params['_types'] = types
            }
            const eventIds: IEvent[] = await getWebsiteEvents({
                    clubPrefix: prefix as string,
                    params
                }).catch(() => [])
            nextEventIds = eventIds.slice(offset, offset + CALENDAR_PAGE_PAGINATION_LIMIT)
            setCurrentEventIds(eventIds)
        } else {
            nextEventIds = currentEventIds.slice(offset, offset + CALENDAR_PAGE_PAGINATION_LIMIT)
        }
        const newEvents = await Promise.all(nextEventIds.map(e => getEvent(e.id, { clubPrefix: prefix as string }).catch(() => null)))
            .then(result => result.filter(Boolean)) // remove nulls
        if(newEvents.length === 0) {
            setHasMore(false)
        }
        setActivePage(page => page+1)
        setCurrentEvents(prev => sortCalendarEventsByStartDate(prev.concat(newEvents)))
        setIsFetching(false)
    }
}