import { ITheme } from '@/shared/styles/MuiTheme'
import MuiChip, { ChipProps as MuiChipProps } from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/styles'

interface IChipProps extends MuiChipProps {
  active?: boolean
}

type IStyleProps = {
    active?: boolean
}

const useChipStyles = makeStyles((theme: ITheme) => ({
    root: {
        color: ({ active }: IStyleProps) => active ? theme?.props?.sectionMode?.text_accent: theme?.props?.sectionMode.text_background,
        backgroundColor: ({ active }: IStyleProps) => {
            return active ? theme?.props?.sectionMode.accent : theme.props.sectionMode.background
        },
        border: ({ active }: IStyleProps) => {
            const color = active ? theme?.props?.sectionMode.accent : theme?.props?.sectionMode.text_background
            return `1px solid ${color}`
        },
        transitionProperty: 'background-color, box-shadow, border',
        '&&:hover': {
            backgroundColor: () => {
                return theme?.props?.sectionMode.accent
            },
            color: () => theme?.props?.sectionMode?.text_accent
        },
        '&&:focus': {
            color: ({ active }: IStyleProps) => active ? theme?.props?.sectionMode?.text_accent: theme?.props?.sectionMode.text_background,
            backgroundColor: ({ active }: IStyleProps) => {
                return active ? theme?.props?.sectionMode.accent : theme.props.sectionMode.background
            },
        },
    },
    label: theme.typography.body2
}))
export default function Chip(props: IChipProps) {
    const { active, ...restProps } = props
    const classes = useChipStyles({active})

    return (
        <MuiChip classes={classes} variant='outlined' {...restProps} />
    )

}