import { useCalendarStyles } from './styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import { Headline3, Paragraph1, Paragraph2 } from '@/shared/components/Typography'
import { Slug } from '@/shared/components/Slug'
import SecondaryButton from '@/shared/components/Button/SecondaryButton'
import { Img } from '@/shared/components/Img/Img'
import { ICalendarListProps, IEventOrGame } from './types'
import { checkDateInPast, displayGameScore, displayLiveStreamButton, displayReplayButton } from '@/shared/utils/helpers'
import { IGroup } from '@/shared/models/Group'
import { generateTagLabel } from '@/shared/utils/generateTeamName'
import { useTranslations } from 'contexts/translations'
import CalendarPlaceholder from './CalendarPlaceholder'
import { DEFAULT_TEAM_LOGO } from '@/shared/components/Avatar/Avatar'
import { useIntl } from 'react-intl'
import { sectionMessages } from 'components/Sections/messages'
import { getVenueDescription } from '@/shared/utils/addressFunc'
import { useSystemPagePath } from 'utils/hooks'
import { WebsitePageType } from '@/shared/models/Website'
import { useMemo } from 'react'
import Loader from '@/shared/components/Loader/Loader'
import { IGame } from '@/shared/models/Game'
import { isUndefined } from '@/shared/utils/lodashFunc'
import LiveStreamButton from '@/shared/components/Button/LiveStreamButton'
import { PenaltyScore } from '@/shared/components/PenaltyScore/PenaltyScore'
import Tags from '@/shared/components/Tags/Tags'

const iconPath = `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}`
export default function CalendarList(props: ICalendarListProps) {
    const { events, categories, countries, isFetching, placeholders, tags } = props
    const { translations, simpleDateFormatter, multipleDaysFormatter } = useTranslations()
    const messages = useMemo(() => ({...translations.messages, ...placeholders[translations.locale]}), [translations, placeholders])
    const classes = useCalendarStyles({})
    const intl = useIntl()
    const trainingBaseLink = useSystemPagePath(WebsitePageType.TRAINING)
    const eventBaseLink = useSystemPagePath(WebsitePageType.EVENT)
    const gameBaseLink = useSystemPagePath(WebsitePageType.GAME)
    return (
        <>
            {events.length === 0 && !isFetching && (
                <CalendarPlaceholder />
            )}
            {events.length > 0 && (
                <Box className={classes.listRoot}>
                    {events.map(e => (
                        <Grid key={e.id} spacing={0} container justifyContent='center'>
                            <Hidden smDown>
                                <Grid container justifyContent='flex-end' item md={6} className={classes.leftSide}>
                                    <div className={`${classes.borderWrapper} left`}>
                                        <div className={`${classes.boxWrapper} ${classes.boxWrapperMaxWidth}`}>
                                            {renderInfo(e)}
                                        </div>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Grid container justifyContent='flex-start' item sm={12} xs={12} md={6} className={classes.verticalLine}>
                                <div className={`${classes.borderWrapper} right`}>
                                    <div className={classes.boxWrapper}>
                                        <div className={classes.spacer}>
                                            <Slug imgSrc={getIcon(e)} text={<Paragraph2 isAccent><b>{getName(e)}</b></Paragraph2>}/>
                                        </div>
                                        <Hidden mdUp>
                                            <div className={classes.spacer}>
                                                {renderInfo(e)}
                                            </div>
                                        </Hidden>
                                        {e.type === 'event' && (
                                            <Box pb={4}>
                                                <Paragraph1><b>{e.title}</b></Paragraph1>
                                            </Box>
                                        )}
                                        {e.type === 'game' && (
                                            <div className={classes.spacer}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={4}>
                                                        {renderTeam(e.team1)}
                                                    </Grid>
                                                    <Grid item container justifyContent='center' alignItems='center' xs={4}>
                                                        <div className={classes.score}>
                                                            <Paragraph1 className={`${e.cancelled && hasScore(e) && classes.cancelledText} ${classes.scoreParagraph}`}>
                                                                <b>{displayGameScore(e)}</b>
                                                                {(typeof e.score1_penalties === 'number' && typeof e.score2_penalties === 'number') &&
                                                                    <PenaltyScore
                                                                        scorePenalty1={e.score1_penalties}
                                                                        scorePenalty2={e.score2_penalties}
                                                                    />
                                                                }
                                                            </Paragraph1>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {renderTeam(e.team2)}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )}
                                        {e.type === 'training' && getRelevantTeams(e).length > 0 && (
                                            <div className={classes.spacer}>
                                                {getRelevantTeams(e).map(team => (
                                                    <div key={team.group.id} className={classes.teamSpacing}>
                                                        <Img
                                                            src={team.group?.pic || DEFAULT_TEAM_LOGO}
                                                            failoverImage={DEFAULT_TEAM_LOGO}
                                                        />
                                                        <div className={classes.trainingTeam}>
                                                            <Paragraph1>
                                                                <b>
                                                                    {generateTagLabel(team.group, null, categories, {
                                                                        useTranslationsInsteadOfIntl: true,
                                                                        translations: messages
                                                                    })}
                                                                </b>
                                                            </Paragraph1>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <SecondaryButton applyBackgroundColor href={getLink(e)}>{intl.formatMessage(sectionMessages['calendar.more_information'])}</SecondaryButton>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            )}
            {isFetching && (
                <Box>
                    <Loader />
                </Box>
            )}
        </>
    )

    function hasScore(game: IGame): boolean {
        return !isUndefined(game?.score1) || !isUndefined(game?.score2)
    }

    function getLink(element: IEventOrGame) {
        if(element.type === 'game') {
            return [gameBaseLink, element.id].join('/') 
        } else if(element.type === 'training') {
            return [trainingBaseLink, element.id].join('/')
        }
        return [eventBaseLink, element.id].join('/')
    }

    function renderInfo(item: IEventOrGame) {
        const showLiveStreamButton = displayLiveStreamButton(item)
        const showReplayButton = displayReplayButton(item)
        return (
            <>
                <Headline3>
                    {item.cancelled ? intl.formatMessage(sectionMessages['games.cancelled']) : multipleDaysFormatter(item?.start_date, item?.end_date)}
                </Headline3>
                {(showLiveStreamButton || showReplayButton) && (
                    <Box py={1}>
                        <LiveStreamButton streamLink={item.stream_link} showLiveStream={showLiveStreamButton} />
                    </Box>
                )}
                <Paragraph1>
                    {simpleDateFormatter(item.start_date, 'dddd | HH:mm')}
                </Paragraph1>
                <Paragraph1>
                    {getVenueDescription(item, countries, messages)}
                </Paragraph1>
                <Tags assignedTags={item.tags?.filter(t => !t.expiration_date || !checkDateInPast(t.expiration_date))} tags={tags} />
            </>
        )
    }

    function getRelevantTeams(e: IEventOrGame) {
        return e.event_teams ?? []
    }

    function getName(item: IEventOrGame) {
        if(item.type === 'training') {
            return item.title
        }
        if(item.type === 'event') {
            return intl.formatMessage(sectionMessages['section.calendar.event'])
        }
        return item.competition?.name ?? intl.formatMessage(sectionMessages['games.friendly_game'])
    }

    function getIcon(item: IEventOrGame) {
        if(item.type === 'training') {
            return `${iconPath}/websites/Time.svg`
        }
        if(item.type === 'event') {
            return `${iconPath}/websites/Event.svg`
        }
        return `${iconPath}/websites/Match.svg`
    }

    function renderTeam(group: IGroup) {
        const label = generateTagLabel(group, null, categories, {
            useTranslationsInsteadOfIntl: true,
            translations: messages
        })
        const teamLogo = group?.pic || DEFAULT_TEAM_LOGO
        return (
            <div
                className={classes.teamRoot}
            >
                <Img
                    src={teamLogo}
                    failoverImage={DEFAULT_TEAM_LOGO}
                />
                <div className={`${classes.teamSpacing} ${classes.team2line}`}>
                    <Paragraph2>
                        <b>
                           {label}
                        </b>
                    </Paragraph2>
                </div>
            </div>
        )
    }
}