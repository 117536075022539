import { IConnectedAccount } from '@/shared/models/User';
import  { request } from './api'

import { IAttendanceItem, IAttendanceRole, IEvent, IEventAttendance, IUserEventItem } from '@/shared/models/Event'


export function getEvent(id: number, { clubPrefix, token = '', context }: { clubPrefix: string; token?: string, context?: any }): Promise<IEvent> {
    return request({
        endpoint: `/events/${id}`,
        method: 'GET',
        clubPrefix,
        isPrivate: token?.length > 0,
        userToken: token,
        context
    })
}

// to get full information for a specific event
export function getEventInfo({ eventId, clubPrefix, token = '' }: { eventId: number, clubPrefix: string; token: string }): Promise<IUserEventItem> {
    const endpoint = `/me/events/${eventId}`

    return request({
        endpoint: endpoint,
        method: 'GET',
        clubPrefix,
        isPrivate: true,
        userToken: token
    })
}

// to get user attendance for a specific event
export function getEventAttendance({ eventId, userId, clubPrefix, token = '' }: { eventId: number; userId: number; clubPrefix: string; token: string }): Promise<IEventAttendance> {
    const endpoint = `/me/events/${eventId}/users/${userId}/attendance`

    return request({
        endpoint: endpoint,
        method: 'GET',
        clubPrefix,
        isPrivate: true,
        userToken: token
    })
}

export function getEventRolesRequest({ eventId, clubPrefix, token }: { eventId: number; clubPrefix: string; token: string }): Promise<IAttendanceRole[]> {
    return request({
        endpoint: `/events/${eventId}/roles`,
        method: 'GET',
        clubPrefix,
        isPrivate: true,
        userToken: token
    })
}

export function assignUserRoleRequest({ eventId, roleId, userId, clubPrefix, token = '' }: {eventId: number; roleId: number; userId: number; clubPrefix: string; token: string}): Promise<[]> {
    return request({
        endpoint: `/me/events/${eventId}/roles/${roleId}/users/${userId}`,
        method: 'POST',
        clubPrefix,
        isPrivate: true,
        userToken: token
    })
}

export function unassignUserRoleRequest({ eventId, roleId, userId, clubPrefix, token = '' }: {eventId: number; roleId: number; userId: number; clubPrefix: string; token: string }): Promise<[]> {
    return request({
        endpoint: `/me/events/${eventId}/roles/${roleId}/users/${userId}`,
        method: 'DELETE',
        clubPrefix,
        isPrivate: true,
        userToken: token
    })
}

// to update user attendance for a specific game event
export function updateGameEventAttendance(
    { sceneId, userId, type, message = '', clubPrefix, token = '' }: { sceneId: number; userId: number; type: string; message?: string; clubPrefix: string; token: string }
): Promise<IAttendanceItem> {
    const endpoint = `/me/scenes/${sceneId}/users/${userId}/attendance`
    const bodyParams = {
        type,
        comment: message
    }

    return request({
        endpoint: endpoint,
        method: 'PATCH',
        body: bodyParams,
        clubPrefix,
        isPrivate: true,
        userToken: token
    })
}

// to update user attendance for a specific event
export function updateEventAttendance(
    { eventId, userId, type, message = '', clubPrefix, token = '' }: { eventId: number; userId: number; type: string; message?: string; clubPrefix: string; token: string }
): Promise<IAttendanceItem> {
    const endpoint = `/me/events/${eventId}/users/${userId}/attendance`
    const bodyParams = {
        type,
        comment: message
    }

    return request({
        endpoint: endpoint,
        method: 'PATCH',
        body: bodyParams,
        clubPrefix,
        isPrivate: true,
        userToken: token
    })
}

// to update user attendance for a specific event
export function deleteEvent(
    { eventId, deleteAll, clubPrefix, token = '' }: { deleteAll: boolean, eventId: number; clubPrefix: string; token: string }
): Promise<IAttendanceItem> {
    const endpoint = `/events/${eventId}${deleteAll ? '/all' : ''}`
    return request({
        endpoint: endpoint,
        method: 'DELETE',
        clubPrefix,
        isPrivate: true,
        userToken: token
    })
}


export async function getWebsiteEvents({ clubPrefix, params, token }: { clubPrefix: string, params: { [key in string]?: any}, token?: string }): Promise<IEvent[]> {
    return request({
        endpoint: '/events',
        method: 'GET',
        clubPrefix,
        query: params,
        userToken: token
    })
}

export function getConcernedEventChildren({ eventId }: {eventId: number}): Promise<{ id: number }[]> {
    return request({
        endpoint: `/me/events/${eventId}/concerned`,
        method: 'GET',
    })
}

export function getConnectedAccounts(): Promise<IConnectedAccount[]> {
    const endpoint = '/me/children'

    return request({
        endpoint: endpoint,
        method: 'GET',
    })
}