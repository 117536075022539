import { useCalendarStyles } from '../styles'
import Box from '@material-ui/core/Box'
import {Headline2 } from '@/shared/components/Typography'
import SecondaryButton from '@/shared/components/Button/SecondaryButton'
import { useIntl } from 'react-intl'
import { sectionMessages } from 'components/Sections/messages'
import CalendarList from '../CalendarList'
import { useSystemPagePath } from 'utils/hooks'
import { WebsitePageType } from '@/shared/models/Website'
import { ISectionComponentProps } from 'components/Sections/utils'
import { gameSorter } from '@/shared/utils/helpers'
import { useMemo } from 'react'

export default function PreviewCalendar(props: ISectionComponentProps) {
    const { section } = props
    const { content } = section 
    const { categories, countries, events, placeholders, tags } = props.data
    const transformedEvents = useMemo(() => {
        const copy = [...events]
        copy.sort(gameSorter)
        return copy
    }, [events])
    const classes = useCalendarStyles({})
    const intl = useIntl()
    const calendarLink = useSystemPagePath(WebsitePageType.CALENDAR, { ...content?.team?.id ? { 'groups': content.team.id } : {} })
    return (
        <Box>
            <Box pb={4}>
                <Headline2>{section?.name}</Headline2>
            </Box>
            <CalendarList placeholders={placeholders} events={transformedEvents} categories={categories} countries={countries} tags={tags} />
            {transformedEvents.length > 0 && (
                <div className={classes.viewMore}>
                    <SecondaryButton applyBackgroundColor href={calendarLink}>{intl.formatMessage(sectionMessages['website.view_more'])}</SecondaryButton>
                </div>
            )}
        </Box>
    )
}