
import { defineMessages, IntlShape } from 'react-intl'
import { IGroup } from '../models/Group'
import { IMessages } from '../models/Messages'

const MALE = 'gender.male'
const FEMALE = 'gender.female'
const MIXED = 'gender.mixed'
const ANY = 'gender.any'

export const messages = defineMessages({ //eslint-disable-line no-unused-vars
    [MALE]: {
        id: MALE,
        defaultMessage: 'Male'
    },
    [FEMALE]: {
        id: FEMALE,
        defaultMessage: 'Female'
    },
    [MIXED]: {
        id: MIXED,
        defaultMessage: 'Mixed'
    },
    [ANY]: {
        id: ANY,
        defaultMessage: 'Any'
    }
})

export const FORM_DEFAULT_LOGO_SIZE = 200
export const genders = [
    {
        id: 0,
        name: messages[MALE],
        short: 'M',
        value: 0
    },
    {
        id: 1,
        name: messages[FEMALE],
        short: 'F',
        value: 1
    }
]

export const gendersWithMixed = [
    ...genders,
    {
        id: 2,
        name: messages[MIXED],
        short: 'M/F',
        value: 2
    }
]

export const gendersWithAny = [
    {
        id: 3,
        name: messages[ANY],
        short: '-',
        value: 3
    },
    ...genders,
]

export const generateGroupFullName = (group: IGroup, messages: IMessages) => {
    const gender = gendersWithMixed.find(g => g.id === group?.gender)
    const groupName = group.name || ''
    const groupSubName = group.subname || ''
    const groupCategory = group?.category?.name ? messages[group.category.name] : ''
    const groupGender = gender && gender.short
    const textOnly = `${groupName} ${groupSubName} ${groupCategory || groupGender ? `(${groupCategory ? groupCategory : ''}${groupGender ? `${groupCategory? ' ': ''}${groupGender}` : ''})` : ''}`
    return textOnly
}

export function getGroupOptions(groups: IGroup[], messages: IMessages, displaySport?: boolean) {
    return groups.map(group => {
        const sportName = group?.sport?.name || ''
        const teamName = generateGroupFullName(group, messages)
        return {
            value: group.id,
            label: [teamName, displaySport && sportName].filter(Boolean).join(' ')
        }
    })
}