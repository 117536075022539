import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { IAssignedTag, ITag } from '@/shared/models/Tag';
import TagManagementTag from '@/shared/components/TagManagementTag/TagManagementTag';

const tagsStyles = makeStyles(() => ({
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
  },
}));

interface IProps {
  assignedTags: IAssignedTag[];
  tags: ITag[];
}

const Tags: React.FunctionComponent<IProps> = (props) => {
  const { tags = [], assignedTags = [] } = props;
  const classes = tagsStyles();

  return (
    <div className={classes.tags}>
      {assignedTags.map((assignedTag) => {
        const tagsLength = assignedTags.filter((t) => t.tag.id === assignedTag.tag.id).length;
        const tag = tags.find((t) => t.id === assignedTag.tag.id);
        if (!tag) return null;
        return (
          <TagManagementTag
            name={tag.name}
            color={tag.color}
            assignationDate={tagsLength > 1 ? assignedTag.assignation_date : ''}
          />
        );
      })}
    </div>
  );
};

export default Tags;
