import { SectionLayoutType } from '@/shared/models/Section';
import { isEmpty } from '@/shared/utils/lodashFunc';
import { useTranslations } from 'contexts/translations';
import { useEffect } from 'react';
import { ISectionComponentProps } from '../utils';
import CalendarDefaultLayout from './Layout/CalendarDefaultLayout';
import PreviewCalendar from './Layout/PreviewCalendar';

export default function CalendarContainer(props: ISectionComponentProps) {
    const {extendTranslations } = useTranslations()
    const { placeholders } = props.data
    const { section } = props
    useEffect(() => {
        if(!isEmpty(placeholders))
            extendTranslations(placeholders)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placeholders])


    if(section.layout === SectionLayoutType.DEFAULT) {
        return <CalendarDefaultLayout {...props} />
    }
    return (
        <PreviewCalendar
            {...props}
        />
    )
}